<template>
  <div class="box">
    <router-link
      v-if="$can('admin/integral/shop-create')"
      :to="{ path: '/integral/shop/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新建积分商品
      </el-button>
    </router-link>
    <div
      class="box-toolbar">

      <div class="box-item">
        <el-input
          placeholder="请输入商品名称关键字"
          v-model="name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>

      <div class="box-item">
        <el-select
          v-model="typeid"
          placeholder="请选择类型"
           @change="changeTypesStatus"
        >
          <el-option
            v-for="(item,index) in types"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
      height="75vh"
    >
      <el-table-column
        width="90"
        prop="id"
        label="序号"
        fixed
      >
      </el-table-column>

      <el-table-column
        prop="integral_name"
        label="名称"
      >
      </el-table-column>

      <el-table-column
        width="150"
        prop="integral_price"
        label="价格"
      >
      </el-table-column>

       <el-table-column
        prop="integral_false_price"
        label="横线价格"
      >
      </el-table-column>

      <el-table-column
        prop="type"
        label="类型"
      >
      <template slot-scope="scope">
        <span v-if="scope.row.type === '1'">兑换码</span>
        <span v-if="scope.row.type === '2'">优惠劵</span>
        <span v-if="scope.row.type === '3'">商品</span>
      </template>
      </el-table-column>

      <el-table-column
        width="200"
        label="操作"
      >
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/integral/shop-edit')"
                @click.native="handleModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/integral/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    ></page>
  </div>
</template>

<script>
import IntegralService from '@admin/services/IntegralService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'IntegralShop',
  data () {
    const TYPES = [
      {
        id: 0,
        name: '全部类型'
      },
      {
        id: 1,
        name: '兑换码'
      },
      {
        id: 2,
        name: '优惠劵'
      },
      {
        id: 3,
        name: '商品'
      }
    ]
    return {
      function: function (param) {
        return IntegralService.shops(param)
      },
      delete (param) {
        return IntegralService.delete(param)
      },
      typeid: '',
      types: TYPES,
      name: '' // 搜索关键字
    }
  },

  mixins: [pagination],
  components: { Page },

  methods: {
    changeTypesStatus () {
      this.searchGo()
    },
    async searchGo () {
      this.page = 1
      let param = {
        type: this.typeid,
        integral_name: this.name
      }
      this.where = param
      await this.getList(param)
    }
  }

}

</script>
<style lang='' scoped>

</style>
